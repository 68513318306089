import PropTypes from "prop-types";
import { LatestArticles } from "@components/latestArticles";

export const DESTINATION_PROP_TYPES = {
  coordinates: PropTypes.shape({
    lat: PropTypes.string.isRequired,
    lon: PropTypes.string.isRequired,
  }),
  editorial: PropTypes.string,
  featuredImage: PropTypes.shape({
    alt: PropTypes.string,
    caption: PropTypes.string,
    credit: PropTypes.string,
    url: PropTypes.string.isRequired,
  }),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      caption: PropTypes.string,
      credit: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  meta: PropTypes.shape({
    fullTitle: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string,
        tag: PropTypes.string,
      }).isRequired
    ).isRequired,
    searchableName: PropTypes.string,
  }).isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  travelAlert: PropTypes.string,
  intro: PropTypes.string.isRequired,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  mustSeePois: PropTypes.arrayOf(
    PropTypes.shape({
      topChoice: PropTypes.bool,
      href: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      miles: PropTypes.number,
      cost: PropTypes.string,
      places: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired
      ),
    })
  ),
  relatedPlaces: PropTypes.arrayOf(
    PropTypes.shape({
      esid: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        alt: PropTypes.string,
        caption: PropTypes.string,
        credit: PropTypes.string,
        height: PropTypes.number,
        name: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number,
      }).isRequired,
    }).isRequired
  ),
  planning: PropTypes.shape({
    primary: PropTypes.arrayOf(
      PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        tag: PropTypes.string.isRequired,
        copy: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    secondary: PropTypes.arrayOf(
      PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        tag: PropTypes.string.isRequired,
        copy: PropTypes.string.isRequired,
      }).isRequired
    ),
  }).isRequired,
  // eslint-disable-next-line react/forbid-foreign-prop-types
  articles: LatestArticles.propTypes.articles,
  // eslint-disable-next-line react/forbid-foreign-prop-types
  ctx: LatestArticles.propTypes.ctx,
  books: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};
