import PropTypes from "prop-types";
import cn from "classnames";

import { tagular } from "@cohesion/tagular";

import {
  CompassFill,
  ClockFill,
  WalletFill,
  TopChoiceBorderlessFeature,
} from "@icons";
import { ImgixNextImage } from "@components/imgixNextImage";
import { Eyebrow } from "@components/eyebrow";

export function PoiCard({
  category = "general",
  className = null,
  count = null,
  cost = null,
  description = null,
  duration = null,
  eyebrow = null,
  image = null,
  miles = null,
  subtitle = null,
  tagularName = null,
  tagularPosition = "NEARBY",
  topChoice = false,
  href,
  title,
}) {
  return (
    <article className={cn("relative rounded", className)}>
      {image && (
        <>
          <ImgixNextImage
            type={category}
            className={cn(
              image ? "rounded" : "rounded-t",
              "relative aspect-square"
            )}
            src={image?.url}
            alt={image?.alt}
            width={600}
            height={400}
            imgixParams={{
              fit: "crop",
              ar: "1:1",
            }}
          />
          {topChoice && (
            <TopChoiceBorderlessFeature
              className="absolute top-4 left-4 z-10"
              width="40px"
              height="40px"
            />
          )}
        </>
      )}
      <div className={cn("space-y-4", image ? "mt-4" : "p-4 bg-white rounded")}>
        {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        <p>
          {/* note: reduced width to account for the withSave hoc button */}
          <a
            className="card-link line-clamp-2 w-[80%] md:w-90"
            href={href}
            onClick={() => {
              tagular("click", {
                actionOutcome: "INTERNALLINK",
                outboundUrl: href,
                webElement: {
                  location: "SECTION",
                  position: tagularPosition,
                  text: title,
                  elementType: "LINK",
                  ...(tagularName && { name: tagularName }),
                },
              });
            }}
          >
            {count && (
              <span className="heading-05">
                {count}
                {". "}
              </span>
            )}
            <span className="heading-05 font-semibold">{title}</span>
          </a>
        </p>
        {subtitle && (
          <p className="text-sm font-semibold uppercase !mt-2">{subtitle}</p>
        )}
        <p
          className={cn(
            "relative flex flex-wrap justify-start items-start gap-x-4 gap-y-2 max-w-full label-sm uppercase",
            { hidden: !miles && !duration && !cost }
          )}
        >
          {miles && (
            <span className="inline-flex items-center">
              <CompassFill className="text-[#563753] mr-1" />
              {miles} MILES
            </span>
          )}
          {!image?.url && (duration || cost) && (
            <>
              {duration && (
                <span className="inline-flex items-center">
                  <ClockFill className="text-[#BA5632] mr-1" />
                  {duration}
                </span>
              )}
              {cost && (
                <span className="inline-flex items-center">
                  <WalletFill className="text-[#607D6D] mr-1" />
                  {cost}
                </span>
              )}
            </>
          )}
        </p>
        <p className="relative line-clamp-3">{description}</p>
        {image?.url && (duration || cost) && (
          <p className="flex items-center gap-x-4 gap-y-2 relative label-sm uppercase">
            {duration && (
              <span className="inline-flex items-center">
                <ClockFill className="text-[#BA5632] mr-1 shrink-0" />
                {duration}
              </span>
            )}
            {cost && (
              <span className="inline-flex items-center">
                <WalletFill className="text-[#607D6D] mr-1 shrink-0" />
                {cost}
              </span>
            )}
          </p>
        )}
      </div>
    </article>
  );
}

PoiCard.propTypes = {
  /** Note: Requires an image to display */
  topChoice: PropTypes.bool,
  count: PropTypes.number,
  href: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  eyebrow: PropTypes.string,
  description: PropTypes.string,
  miles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duration: PropTypes.string,
  cost: PropTypes.string,
  className: PropTypes.string,
  tagularPosition: PropTypes.string,
  tagularName: PropTypes.string,
  category: PropTypes.string,
};
